import { useState, useEffect } from 'react';

function useOnScreen(ref) {
  const [ isIntersecting, setIsIntersecting ] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([ entry ]) => setIsIntersecting(entry.isIntersecting),
      { rootMargin: '-150px' }
    );
    observer.observe(ref.current);
    return () => { observer.disconnect(); };
  }, [ ref ]);

  return isIntersecting;
}

export default useOnScreen;