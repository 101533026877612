import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

import IconButton from './iconButton';

const ConfirmationModal = ({ confirmationModal, setConfirmationModal }) => {
  const { t } = useTranslation();
  const { isOpen, onConfirm } = confirmationModal;
  
  const toggle = () => {
    setConfirmationModal(prev => ({
      isOpen: false,
      onConfirm: () => { }
    }));
  }; 

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t('delete_form_control_confirmation_modal.header')}
      </ModalHeader>
      <ModalBody>
        {t('delete_form_control_confirmation_modal.body')}
      </ModalBody>      
      <ModalFooter>
        <IconButton 
          color='primary' 
          onClick={onConfirm} 
          icon={() => <FaCheck />}
        >
          {t('delete_form_control_confirmation_modal.confirm_button')}
        </IconButton>
        <IconButton
          color='secondary' 
          onClick={toggle}
          icon={() => <IoMdClose />}
        >
          {t('delete_form_control_confirmation_modal.cancel_button')}
        </IconButton>
      </ModalFooter>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  confirmationModal: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired
  }).isRequired,
  setConfirmationModal: PropTypes.func.isRequired
};

export default ConfirmationModal;
