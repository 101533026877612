import React, { useEffect, useState } from 'react';
import { 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  CustomInput,
} from 'reactstrap';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { withSendRequest } from '../../main/hoc/withSendRequest';
import useForm from '../hooks/useForm';
import AnnotationsList from '../components/annotationsList';
import * as notify from '../../main/utils/notify';
import { ErrorBoundary } from '../../main/hoc/errorboundary';

const ExperimentAnnotationsModal = ({ isOpen, toggle, experimentId, sendRequest }) => {
  const [ annotations, setAnnotations ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const { fields, handleFieldChange, clearFields } = useForm({
    annotation: '',
    shared: false,
  });
  const { t } = useTranslation();
  
  useEffect(() => {
    const fetchAnnotations = async () => {
      try {
        setIsLoading(true);
        const response = await sendRequest('get', `/annotation/get-by-experiment/${experimentId}`);
        setAnnotations(response.data);
        setIsLoading(false);
      }
      catch (err) {
        setError(t('annotations_modal.fetching_data_error'));
        setIsLoading(false);
      }
    };
    fetchAnnotations();
  }, [ sendRequest, experimentId, t ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendRequest('post', '/annotation/add-by-experiment', {
        annotation_text: fields.annotation,
        experiment_id: experimentId,
        shared: fields.shared,
      });
      clearFields();
      setAnnotations(prev => [ ...prev, response.data ]);
    }
    catch (err) {
      notify.error(
        t('annotations_modal.notifications.create_error.title'),
        t('annotations_modal.notifications.create_error.body'),
      );
    }
  };

  const handleDelete = async (annotationId) => {
    try {
      const response = await sendRequest('delete', `/annotation/${annotationId}`);
      if (response) {
        setAnnotations(prev => prev.filter(({ id }) => id !== annotationId));
      }
      else {
        notify.error(
          t('annotations_modal.notifications.delete_error_403.title'),
          t('annotations_modal.notifications.delete_error_403.body'),
        );
      }
    }
    catch (err) {
      notify.error(
        t('annotations_modal.notifications.delete_error.title'),
        t('annotations_modal.notifications.delete_error.body'),
      );
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>{t('annotations_modal.header_title')}</ModalHeader>
      <ModalBody>
        <div className="mb-3 w-100">
          <AnnotationsList
            error={error}
            isLoading={isLoading}
            annotations={annotations}
            onDelete={handleDelete}
          />
        </div>
        <Form onSubmit={handleSubmit}>
          <Label htmlFor='annotation'>{t('annotations_modal.input_label')}:</Label>
          <InputGroup>
            <Input 
              id='annotation' 
              name='annotation' 
              onChange={handleFieldChange} 
              value={fields.annotation}
              placeholder={ t('annotations_modal.input_placeholder') }
            />
            <InputGroupAddon addonType='append'>
              <Button 
                type='submit' 
                color='primary' 
                disabled={fields.annotation.length===0 || fields.annotation.length>1024}
              >
                {t('annotations_modal.add_button_label')}
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <CustomInput
            type='switch'
            id='shared'
            name='shared'
            label={t('annotations_modal.share-annotation_switch_text')}
            checked={fields.shared}
            onChange={handleFieldChange}
            className={"mt-1"}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>
          {t('annotations_modal.close_button_label')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ExperimentAnnotationsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  experimentId: PropTypes.number.isRequired,
  sendRequest: PropTypes.func, // HOC
};

export default compose(
  withSendRequest,
  ErrorBoundary((props) => props.t('components.experiment_annotations_modal'))
)(ExperimentAnnotationsModal);
