import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Modal, 
  ModalBody, 
  Alert, 
  Form, 
  Input, 
  Label, 
  Button, 
  ModalHeader,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  ButtonGroup
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { IoMdArrowBack } from 'react-icons/io';
import { AiOutlineZoomOut } from 'react-icons/ai';

import { withSendRequest } from '../../main/hoc/withSendRequest';
import Histogram from '../components/histogram';
import Loading from '../../main/components/loading';
import IconButton from '../components/iconButton';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { getNumberInFormat } from '../utils';

const HistogramModal = ({ 
  isOpen, 
  onCloseHistogramModal, 
  idsField, 
  variableName,
  unit,
  initialMinValue, 
  initialMaxValue, 
  sendRequest 
}) => {
  const [ data, setData ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ previousValues, setPreviousValues ] = useState([]);
  const [ minValue, setMinValue ] = useState(initialMinValue);
  const [ maxValue, setMaxValue ] = useState(initialMaxValue);
  const { t } = useTranslation();

  const getHistogramData = useCallback(async ({ xCount = null, min, max }) => {
    try {
      const response = await sendRequest('post', '/histogram', {
        idsField,
        ...(min && { x0: min }),
        ...(max && { x1: max }),
        xCount
      });

      const { x0, x1, buckets } = response.data;
      const diff = Math.abs(x1 - x0);
      
      const histogramData = buckets.map(bucket => {
        let name, rangeName;

        if (diff < 1) {
          rangeName = `(${getNumberInFormat(bucket.start.toFixed(4))}, ${getNumberInFormat(bucket.end.toFixed(4))})`;
          name = bucket.end === 0 ? `${getNumberInFormat((bucket.start).toFixed(4))}` :
            `${getNumberInFormat(((bucket.start + bucket.end)/2).toFixed(4))}`;
        }
        else if (diff < 10) {
          rangeName = `(${getNumberInFormat(bucket.start.toFixed(3))}, ${getNumberInFormat(bucket.end.toFixed(3))})`;
          name = bucket.end === 0 ? `${getNumberInFormat((bucket.start).toFixed(3))}` :
            `${getNumberInFormat(((bucket.start + bucket.end)/2).toFixed(3))}`;
        }
        else if (diff < 100) {
          rangeName = `(${getNumberInFormat(bucket.start.toFixed(2))}, ${getNumberInFormat(bucket.end.toFixed(2))})`;
          name = bucket.end === 0 ? `${getNumberInFormat((bucket.start).toFixed(2))}` :
            `${getNumberInFormat(((bucket.start + bucket.end)/2).toFixed(2))}`;
        }
        else {
          rangeName = `(${getNumberInFormat(Math.round(bucket.start))}, ${getNumberInFormat(Math.round(bucket.end))})`;
          name = bucket.end === 0 ? `${getNumberInFormat(Math.round(bucket.start))}` : `${getNumberInFormat(Math.round((bucket.start + bucket.end)/2))}`;
        }

        return {
          name,
          rangeName,
          [idsField]: bucket.frequency
        };
      });
      setData(histogramData);
      setMinValue(x0);
      setMaxValue(x1);
      setPreviousValues(prev => [ ...prev, { min: x0, max: x1 } ]);
    }
    catch (err) {
      setError(t('histogram_modal.server_error'));
    }
  }, [ sendRequest, idsField, t ]);

  useEffect(() => {
    getHistogramData({ min: initialMinValue, max: initialMaxValue });
  }, [ getHistogramData, initialMinValue, initialMaxValue ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    getHistogramData({ min: minValue, max: maxValue });
  };

  const handleZoomOut = () => getHistogramData({ min: initialMinValue, max: initialMaxValue });

  const handlePreviousZoom = () => {
    const { min, max } = previousValues[previousValues.length - 2];
    getHistogramData({ min, max });
    setPreviousValues(prev => prev.slice(0, prev.length - 2));    
  };

  const handleClose = (min, max) => onCloseHistogramModal(idsField, min, max);

  const onZoom = (min, max) => getHistogramData({ min, max });

  const renderHistogram = () => {
    if (error) {
      return <Alert color='danger'>{error}</Alert>;
    }
    else if (!data) {
      return <Loading className='text-center' />;
    }
    else {
      return (
        <Histogram
          data={data}
          idsField={idsField}
          onZoom={onZoom}
          variableName={variableName}
          unit={unit}
        />
      );
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={() => handleClose()} size='lg'>
      <ModalHeader toggle={() => handleClose()}>
        {variableName || idsField}{' '}{t('histogram_modal.header')}
      </ModalHeader>
      <ModalBody>
        {renderHistogram()}
        <div className='d-flex justify-content-center align-items-center my-3'>
          <Form inline onSubmit={handleSubmit}>
            <InputGroup>
              <Label htmlFor='min' hidden>Min</Label>
              <Input 
                type='number'
                step='any' 
                name='min' 
                id='min' 
                value={getNumberInFormat(minValue)}
                onChange={e => setMinValue(e.target.value)} 
              />
              <Label htmlFor='max' hidden>Max</Label>
              <Input 
                type='number' 
                step='any'
                name='max' 
                id='max' 
                value={getNumberInFormat(maxValue)}
                onChange={e => setMaxValue(e.target.value)} 
              />
              <InputGroupAddon addonType='append'>
                <Button 
                  type='submit' 
                  color='primary'
                  className='mr-1'
                  title={t('histogram_modal.apply_button_title')}
                  disabled={!!error}
                >
                  {t('histogram_modal.apply_button_label')}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Form>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <ButtonGroup>
            <IconButton
              type='button'
              color='primary'
              onClick={handleZoomOut}
              title={t('histogram_modal.zoom_out_button_title')}
              icon={() => <AiOutlineZoomOut />}
              disabled={!!error}
            >
              {t('histogram_modal.zoom_out_button_label')}
            </IconButton>
            <IconButton
              type='button'
              color='primary'
              onClick={handlePreviousZoom}
              title={t('histogram_modal.previous_zoom_button_title')}
              disabled={previousValues.length === 1 || previousValues.length === 0}
              icon={() => <IoMdArrowBack />}
            >
              {t('histogram_modal.previous_zoom_button_label')}
            </IconButton>
          </ButtonGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button 
          onClick={() => handleClose(minValue, maxValue)}
          color='primary'
          title={t('histogram_modal.submit_filters_button_title')}
        >
          {t('histogram_modal.submit_filters_button_label')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

HistogramModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseHistogramModal: PropTypes.func.isRequired,
  idsField: PropTypes.string.isRequired,
  variableName: PropTypes.string.isRequired,
  unit: PropTypes.string,
  initialMinValue: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  initialMaxValue: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  sendRequest: PropTypes.func.isRequired // HOC
};

export default compose(
  withSendRequest,
  ErrorBoundary((props) => props.t('components.histogram_modal'))
)(HistogramModal);

