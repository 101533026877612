import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconButton = ({ icon: Icon, iconSize, children, ...buttonProps }) => {
  const iconWrapperClass = classNames(
    'mr-2 text-white d-flex align-items-center justify-content-center', 
    {
      'icon-sm': iconSize === 'sm',
      'icon-md': iconSize === 'md',
      'icon-lg': iconSize === 'lg'
    }
  );

  return (
    <Button {...buttonProps}>
      <div className="d-flex align-items-center justify-content-center">
        <div 
          className={iconWrapperClass} 
        >
          <Icon/>
        </div>
        <span>{children}</span>
      </div>
    </Button>
  );
};

IconButton.defaultProps = {
  iconSize: 'md'
};

IconButton.propTypes = {
  icon: PropTypes.func.isRequired,
  iconSize: PropTypes.oneOf([ 'sm', 'md', 'lg' ]),
  children: PropTypes.any
};

export default IconButton;