import { Map } from 'immutable';

import { 
  GET_ENTRY_DATA_ERROR,
  GET_ENTRY_DATA_REQUEST,
  GET_ENTRY_DATA_SUCCESS,
  SET_PAGINATION_INFO,
  GET_EXPERIMENT_INFO_REQUEST,
  GET_EXPERIMENT_INFO_SUCCESS,
  GET_EXPERIMENT_INFO_ERROR,
  SET_SEARCH,
  GET_EXPERIMENT_IDS_FIELDS_ERROR,
  GET_EXPERIMENT_IDS_FIELDS_REQUEST,
  GET_EXPERIMENT_IDS_FIELDS_SUCCESS,
  CLEAR_ENTRY_DATA_ERROR,
  CLEAR_EXPERIMENT_INFO_ERROR,
  GET_FAIR_DATA_SUCCESS,
  GET_FAIR_DATA_REQUEST,
  GET_FAIR_DATA_ERROR
} from '../actions/experimentOverview';

const initialState = Map({
  experimentData: Map({
    loading: false,
    data: null,
    error: null
  }),
  pagination: Map({
    currentPage: null,
    elementsOnPage: null,
    pageSize: null,
    totalElements: null,
    pages: null
  }),
  experimentInfo: Map({
    loading: false,
    data: null,
    source: null,
    experimentId: null,
    error: null
  }),
  experimentIdsFields: Map({
    loading: false,
    data: null,
    error: null
  }),
  fairData: Map({
    loading: false,
    data: [],
    error: null
  }),
  lastSearch: '',
});

export const experimentOverviewReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_ENTRY_DATA_REQUEST:
    return state.setIn([ 'experimentData', 'loading' ], true).setIn([ 'experimentData', 'error' ], null);
  case GET_ENTRY_DATA_SUCCESS:
    return state
      .setIn([ 'experimentData', 'loading' ], false)
      .setIn([ 'experimentData', 'error' ], null)
      .setIn([ 'experimentData', 'data' ], action.payload);
  case GET_ENTRY_DATA_ERROR:
    return state.setIn([ 'experimentData', 'loading' ], false).setIn([ 'experimentData', 'error' ], action.payload);
  case CLEAR_ENTRY_DATA_ERROR:
    return state.setIn([ 'experimentData', 'error' ], null);
  case SET_PAGINATION_INFO:
    const { elementsOnPage, pageSize, currentPage, totalElements, pages } = action.payload;
    return state
      .setIn([ 'pagination', 'elementsOnPage' ], elementsOnPage)
      .setIn([ 'pagination', 'pageSize' ], pageSize)
      .setIn([ 'pagination', 'currentPage' ], currentPage)
      .setIn([ 'pagination', 'totalElements' ], totalElements)
      .setIn([ 'pagination', 'pages' ], pages);
  case GET_EXPERIMENT_INFO_REQUEST:
    return state.setIn([ 'experimentInfo', 'loading' ], true);
  case GET_EXPERIMENT_INFO_SUCCESS:
    const { experimentInfo, source, experimentId } = action.payload;
    return state
      .setIn([ 'experimentInfo', 'loading' ], false)
      .setIn([ 'experimentInfo', 'data' ], experimentInfo)
      .setIn([ 'experimentInfo', 'source' ], source)
      .setIn([ 'experimentInfo', 'experimentId' ], experimentId);
  case GET_EXPERIMENT_INFO_ERROR:
    return state.setIn([ 'experimentInfo', 'loading'  ], false).setIn([ 'experimentInfo', 'error' ], action.payload);
  case CLEAR_EXPERIMENT_INFO_ERROR:
    return state.setIn([ 'experimentInfo', 'error' ], null);
  case SET_SEARCH:
    return state.setIn([ 'lastSearch' ], action.payload);
  case GET_EXPERIMENT_IDS_FIELDS_REQUEST:
    return state
      .setIn([ 'experimentIdsFields', 'loading' ], true);
  case GET_EXPERIMENT_IDS_FIELDS_SUCCESS:
    return state      
      .setIn([ 'experimentIdsFields', 'loading' ], false)
      .setIn([ 'experimentIdsFields', 'data' ], action.payload);
  case GET_EXPERIMENT_IDS_FIELDS_ERROR:
    return state
      .setIn([ 'experimentIdsFields', 'loading' ], false)
      .setIn([ 'experimentIdsFields', 'error' ], action.payload);
  case GET_FAIR_DATA_REQUEST:
    return state
      .setIn([ 'fairData', 'loading' ], true)
      .setIn([ 'fairData', 'error' ], null);
  case GET_FAIR_DATA_SUCCESS:
    return state
      .setIn([ 'fairData', 'loading' ], false)
      .setIn([ 'fairData', 'data' ], action.payload);
  case GET_FAIR_DATA_ERROR:
    return state
      .setIn([ 'fairData', 'error' ], action.payload)
      .setIn([ 'fairData', 'data' ], []);
  default:
    return state;
  }
}; 