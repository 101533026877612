import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const ExperimentNotFoundAlert = ({ error, experimentId }) => {
  if (error === '404') {
    return (
      <Alert className='mt-2' color='danger'>
        <Trans i18nKey='experiment_not_found_message'>
          Experiment with the id of <strong>{{ experimentId }}</strong> does not exist!
          <Link to='/shots' className='alert-link'>Back to experiments list.</Link>
        </Trans>
      </Alert>
    );
  }
  else {
    return (
      <Alert className='mt-2' color='danger'>{error}</Alert>
    );
  }
};

ExperimentNotFoundAlert.propTypes = {
  error: PropTypes.string.isRequired,
  experimentId: PropTypes.string.isRequired
};

export default ExperimentNotFoundAlert;
