import { Map, Set, List } from 'immutable';

import { 
  TOGGLE_SWITCH, 
  UPDATE_FILTERS, 
  SET_SELECTED, 
  SET_UNSELECTED,
  GET_EXPERIMENTS_ERROR,
  GET_EXPERIMENTS_REQUEST,
  GET_EXPERIMENTS_SUCCESS,
  SET_SEARCH,
  SET_PAGINATION_INFO
} from '../actions/experimentsList';

const initialState = Map({
  switchOpen: false,
  currentFilters: Set(),
  selectedExperiments: List(),
  experimentsData: Map({
    loading: false,
    experiments: null,
    error: null
  }),
  pagination: Map({
    currentPage: null,
    elementsOnPage: null,
    pageSize: null,
    totalElements: null,
    pages: null
  }),
  lastSearch: ''
});

export const experimentsListReducer = (state = initialState, action) => {
  switch (action.type) {
  case TOGGLE_SWITCH:
    return state.set('switchOpen', action.payload);
  case UPDATE_FILTERS:
    const filters = action.payload
      .map(({ idsField }) => idsField)
      .filter(idsField => idsField !== 'ids_properties/creation_date');
    return state.set('currentFilters', Set(filters));
  case SET_SELECTED:
    return state.set('selectedExperiments', state.get('selectedExperiments').push(action.payload));
  case SET_UNSELECTED:
    return state.set(
      'selectedExperiments',
      state.get('selectedExperiments').filter(id => id !== action.payload)
    );
  case GET_EXPERIMENTS_REQUEST:
    return state.setIn([ 'experimentsData', 'loading' ], true);
  case GET_EXPERIMENTS_SUCCESS:
    return state
      .setIn([ 'experimentsData', 'loading' ], false)
      .setIn([ 'experimentsData', 'error' ], null)
      .setIn([ 'experimentsData', 'experiments' ], action.payload);
  case GET_EXPERIMENTS_ERROR:
    return state.setIn([ 'experimentsData', 'loading' ], false).setIn([ 'experimentsData', 'error' ], action.payload);
  case SET_SEARCH:
    return state.set('lastSearch', action.payload);
  case SET_PAGINATION_INFO:
    const { elementsOnPage, pageSize, currentPage, totalElements, pages } = action.payload;
    return state
      .setIn([ 'pagination', 'elementsOnPage' ], elementsOnPage)
      .setIn([ 'pagination', 'pageSize' ], pageSize)
      .setIn([ 'pagination', 'currentPage' ], currentPage)
      .setIn([ 'pagination', 'totalElements' ], totalElements)
      .setIn([ 'pagination', 'pages' ], pages);
  default:
    return state;
  }
}; 