import React, { useState } from 'react';
import {
  Modal, 
  ModalBody,
  Button,
  ModalHeader,
  ModalFooter,
  ButtonGroup
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';

import { ErrorBoundary } from '../../main/hoc/errorboundary';


const FeedbackModal = () => {

  const { t } = useTranslation();

  const [ feedbackModal, setFeedbackModal ] = useState({
    isOpen: false
  });

  const handleClose = () => onCloseFeedbackModal();

  const onOpenFeedbackModal = () => {
    setFeedbackModal({
      isOpen: true
    });
  };

  const onCloseFeedbackModal = () => {
    setFeedbackModal({ isOpen: false });
  };

  return (
    <>
      <Button
        type='button'
        color='link'
        style= { {
          color: 'white',
          padding: '0.45rem'
        } }
        size='sm'
        onClick={onOpenFeedbackModal}
      >
        {t('open_feedback_modal_button')}
      </Button>
      { feedbackModal.isOpen && (
        <Modal isOpen={feedbackModal.isOpen} toggle={() => handleClose()} size='lg'>
          <ModalHeader toggle={() => handleClose()}>
            {t('feedback_modal.header')}
          </ModalHeader>
          <ModalBody>
            <div className='d-flex justify-content-center align-items-center my-3'>
              <ul>
                <li>
                  {t('feedback_modal.text1')}
                  <a href={"https://jira.eufus.psnc.pl/projects/CQT2DMP/summary"} target={"_blank"} rel="noreferrer">
                    {t('feedback_modal.link1')}
                  </a>
                </li>
                <li>
                  {t('feedback_modal.text2')}
                  <a href={"https://confluence.eufus.psnc.pl/pages/viewpage.action?pageId=4164392"} target={"_blank"} rel="noreferrer">
                    {t('feedback_modal.link2')}
                  </a>
                </li>
                <li>
                  {t('feedback_modal.text3')}
                  <a href={"mailto:data.managment.portal@gmail.com"} target={"_blank"} rel="noreferrer">
                    {t('feedback_modal.link3')}
                  </a>
                </li>
                <li>
                  {t('feedback_modal.text4')}
                  <a href={"https://forms.gle/5Zr5bzwxnwBpNpUv6"} target={"_blank"} rel="noreferrer">
                    {t('feedback_modal.link4')}
                  </a>
                </li>
              </ul>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <ButtonGroup>
              </ButtonGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => handleClose()}
              color='primary'
              title={t('feedback_modal.close_button_title')}
            >
              {t('feedback_modal.close_button_label')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default compose(
  ErrorBoundary((props) => props.t('components.feedback_modal'))
)(FeedbackModal);

