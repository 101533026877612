import React from 'react';
import { Container, Row, Col, Label, InputGroup, Input, Form, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useForm from '../hooks/useForm';


const JumpToShot = () => {

  const { fields, handleFieldChange } = useForm({
    jump_to_shot_id: ''
  });

  const history = useHistory();

  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(`/shots/${fields.jump_to_shot_id}`);
  };

  return (
    <Container>
      <Row className="justify-content-end">
        <Label className="mb-0">{t('jump_to_shot_label')}</Label>
        <Col>
          <Form onSubmit={handleSubmit}>
            <InputGroup>
              <Input
                type='number'
                min="1"
                name='jump_to_shot_id'
                value={fields.jump_to_shot_id}
                onChange={handleFieldChange}
              />
              <Button type='submit' color='secondary' className='mr-2'>{t('jump_to_shot_button')}</Button>
            </InputGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );

};

export default (JumpToShot);
