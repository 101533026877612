import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import Callback from './callback';
import LogoutCallback from './logoutCallback';
import { checkIfLoginIsPossible, loginCallbackPath, logoutCallbackPath } from '../utils/authUtils';
import LoginToAccess from '../components/loginToAccess';
import NotFound from '../components/notFound';
import Loading from '../components/loading';

import TopBar from '../../dashboard-ui/containers/topbar';
import Main from '../../dashboard-ui/containers/main';
import '../../dashboard-ui/styles/index.scss';
import ExperimentView from '../../dashboard-ui/containers/experimentView';
import Administration from '../../dashboard-ui/containers/adminView';
import Footer from '../../dashboard-ui/components/footer';
import { __env } from '../../envloader';

@connect(
  state => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress'),
  })
)
export default class Root extends Component {
  renderContent = () => {

    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading className="text-center mt-5" />;
    }

    if (checkIfLoginIsPossible() && !this.props.loginStore) {
      //User not logged in
      return (
        <Switch>
          <Route exact strict path='/' component={LoginToAccess} />
          <Route exact path='/admin' component={LoginToAccess} />
          <Route exact path='/shots' component={LoginToAccess} />
          <Route exact path='/shots/:id' component={LoginToAccess} />
          <Route path={ loginCallbackPath } exact component={Callback} />
          <Route path={ logoutCallbackPath } exact component={LogoutCallback} />
          <Route component={NotFound} />
        </Switch>
      );
    }
    else {
      //User logged in or login impossible in this application instance
      return (
        <Switch>
          <Route exact strict path='/' component={Main} />
          <Route exact path='/admin' component={Administration} />
          <Route exact path='/shots' component={Main} />
          <Route exact path='/shots/:id' component={ExperimentView} />
          <Route component={NotFound} />
        </Switch>
      );
    }
  };

  render() {
    return (
      <div className="root">
        <TopBar />
        <Container className={"main" + (!this.props.loginStore && checkIfLoginIsPossible() ? " main-image-holder" +
          (__env.DASHBOARD_FLAVOUR_NUMBER===1 ? " main-image-holder-west":"") :"") } fluid>
          {this.renderContent()}
        </Container>
        <Footer />
      </div>
    );
  }
}

Root.propTypes = {
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
};
