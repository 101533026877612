import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Container, Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
//import { useTranslation } from 'react-i18next';


import { withSendRequest } from '../../main/hoc/withSendRequest';

import Loading from '../../main/components/loading';

import { ErrorBoundary } from '../../main/hoc/errorboundary';

import AdministrationUsersList from '../components/adminUsersList';
import AdministrationRolesList from '../components/adminRolesList';
import * as notify from '../../main/utils/notify';
import AxiosInstance from '../../main/utils/axios/axiosInstance';


const AdminView = ({ sendRequest }) => {
  const [ users, setUsers ] = useState([]);
  const [ allRoles, setAllRoles ] = useState([]);
  const [ actRoles, setActRoles ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState('');
  const [ selectedUserId, setSelectedUserId ] = useState(null);

  //const { t } = useTranslation();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const response = await sendRequest('get', `/user`);
        setUsers(response.data);
        setIsLoading(false);
      }
      catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, [
    sendRequest
  ]);

  useEffect(() => {
    const fetchAllRoles = async () => {
      try {
        setIsLoading(true);
        const response = await sendRequest('get', `/role`);
        setAllRoles(response.data);
        setIsLoading(false);
      }
      catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };
    fetchAllRoles();
  }, [
    sendRequest
  ]);

  const fetchActRoles = useCallback(async () => {
    if (selectedUserId===null) {
      notify.warning("Select user first!");
      return;
    }
    try {
      const response = await sendRequest('get', `/user/${selectedUserId}/role`);
      setActRoles(response.data);
    }
    catch (err) {
      setError(err.message);
    }
  }, [ selectedUserId, sendRequest ]);

  const onChooseUser = (userId) => {
    setSelectedUserId(userId);
  };

  useEffect(() => {
    if (selectedUserId!==null) { fetchActRoles(); }
  }, [ selectedUserId, fetchActRoles ]);

  const manageRole = (roleId, deleteRole = false) => {

    if (selectedUserId===null) {
      notify.warning("Hint:","Select user first!");
      return;
    }

    try {
      AxiosInstance({
        method: deleteRole ? 'delete' : 'put',
        url: `/user/${selectedUserId}/role/${roleId}`,
        __silentFor: [ { status: 400 } ] }
      ).then( (response) => {
        if (response.status === 200) fetchActRoles();
      }).catch( (err) => {
        notify.error("Error:",err?.response?.data?.message || err?.message);
      });
    }
    catch (err) {
      notify.error("Error:", err.message);
    }
  };

  const onAddRole = (roleId) => {
    manageRole(roleId);
  };

  const onDeleteRole = (roleId) => {
    manageRole(roleId, true);
  };

  return (
    <Container fluid className='mt-3'>
      <Row>
        <Col sm="4">
          <Card>
            <CardHeader>
              Users:
            </CardHeader>
            <CardBody>
              {(isLoading || !users) ? (
                <Loading className='text-center' />
              ) : (
                <>
                  <AdministrationUsersList
                    error={error}
                    isLoading={isLoading}
                    users={users}
                    chooseUser={onChooseUser}
                    selectedId={selectedUserId}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col sm="4">
          <Card>
            <CardHeader>
              User assigned roles:
            </CardHeader>
            <CardBody>
              {(isLoading) ? (
                <Loading className='text-center' />
              ) : (
                <>
                  <AdministrationRolesList
                    error={error}
                    isLoading={isLoading}
                    roles={actRoles}
                    deleteRole={onDeleteRole}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col sm="4">
          <Card>
            <CardHeader>
              All roles:
            </CardHeader>
            <CardBody>
              {(isLoading) ? (
                <Loading className='text-center' />
              ) : (
                <>
                  <AdministrationRolesList
                    error={error}
                    isLoading={isLoading}
                    roles={allRoles}
                    addRole={onAddRole}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

AdminView.propTypes = {
  sendRequest: PropTypes.func.isRequired, // HOC
};

export default compose(
  withSendRequest,
  ErrorBoundary((props) => props.t('components.admin_view'))
)(AdminView);

