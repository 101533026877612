import React from 'react';
import { FormGroup, Label, Input, Button, InputGroup, ButtonGroup, InputGroupAddon } from 'reactstrap';
import { FaTrash, FaChartBar } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getNumberInFormat } from '../utils';

const NumberFormControl = ({ 
  label, 
  idsField,
  unit,
  minValue, 
  maxValue,
  minValueName,
  maxValueName, 
  variableTypeId,
  onChange,
  onDelete,
  onOpenHistogramModal
}) => {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <Label>{label}</Label>
      <InputGroup>
        <Input
          name={minValueName}
          type='number'
          step='any'
          value={getNumberInFormat(minValue)}
          onChange={onChange}
        />
        <Input
          name={maxValueName}
          type='number'
          step='any'
          value={getNumberInFormat(maxValue)}
          onChange={onChange}
        />
        <InputGroupAddon addonType="append">
          <ButtonGroup>
            <Button
              color="primary"
              className='py-1 px-2 trash-icon-button btn-border-gray'
              onClick={onDelete}
              title={t('delete_form_control_button_title')}
            >
              <FaTrash style={{ fontSize: '1.1rem' }} />
            </Button>
            {variableTypeId === 2 && (
              <Button
                color="primary"
                className='py-1 px-2 btn-border-gray'
                onClick={() => onOpenHistogramModal(idsField, label, unit, minValue, maxValue)}
                title={(t('open_histogram_button_label'))}
              >
                <FaChartBar style={{ fontSize: '1.1rem' }} />
              </Button>
            )}
          </ButtonGroup>
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
  );
};

NumberFormControl.propTypes = {
  label: PropTypes.string.isRequired,
  idsField: PropTypes.string.isRequired,
  unit: PropTypes.string,
  minValue: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  maxValue: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  minValueName: PropTypes.string.isRequired,
  maxValueName: PropTypes.string.isRequired,
  variableTypeId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onOpenHistogramModal: PropTypes.func.isRequired
};

export default NumberFormControl;