import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import NicePic from '../../assets/images/3D10.06-4c.jpg';
import NicePicWest from '../../assets/images/west.png';
import { __env } from '../../envloader';

const RawDataModal = ({ isOpen, toggle, source, machine, sourceAddress, onOpenJupyter }) => {
  const { t } = useTranslation();

  const showOpenJupyter = machine === 'WEST' && source?.startsWith('uda:');

  return (
    <Modal isOpen={isOpen} backdrop={true} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('raw_data_modal.title')}</ModalHeader>
      <ModalBody>
        <p><img src={__env.DASHBOARD_FLAVOUR_NUMBER===1 ? NicePicWest : NicePic} className={"w-100"} alt={t('raw_data_modal.image_alt')}/></p>
        <p>{t('raw_data_modal.source')}<br/>
          {source}
        </p>
        <p>
          <Trans i18nKey="raw_data_modal.source_address">
            You may go <a href={sourceAddress} target="_blank" rel="noopener noreferrer">there</a> and find the raw and complete data
          </Trans>
        </p>
      </ModalBody>  
      <ModalFooter
        className={showOpenJupyter ? 'justify-content-between' : 'justify-content-end'}
      >
        {showOpenJupyter && <Button onClick={onOpenJupyter}>{t('raw_data_modal.open_jupyter')}</Button>}
        <Button onClick={toggle}>{t('raw_data_modal.close')}</Button>
      </ModalFooter>
    </Modal>
  );
};

export default RawDataModal;

RawDataModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  source: PropTypes.string,
  machine: PropTypes.string,
  sourceAddress: PropTypes.string,
  onOpenJupyter: PropTypes.func.isRequired,
};