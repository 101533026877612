const forceUrlsWithTrailingSlashes = envs => {

  // Add here every new env, which contain url, for forcing trailing slash
  const processedEnvs = [ 'AUTH_OPENID_PROVIDER', 'CATALOG_QT_API_URL', 'JUPYTER_API_URL' ];

  for (let index = 0; index < processedEnvs.length; index++) {
    const property = processedEnvs[index];
    if (envs.hasOwnProperty(property)) {
      let val = envs[property];
      if (val && typeof val === 'string' && val.length) {
        if (val.startsWith('http') && val.slice(-1) !== "/") {
          envs[property] = val + "/";
        }
      }
    }
  }
};

export const __env = {};

forceUrlsWithTrailingSlashes(window.__env);

Object.assign(__env, window.__env);

__env.NODE_ENV = process.env.NODE_ENV;
__env.PUBLIC_URL = process.env.PUBLIC_URL;

