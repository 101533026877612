import { defaultVariables } from '../constants';

const namespace = '@DASHBOARD_UI/LIST/';

export const TOGGLE_SWITCH = namespace + 'TOGGLE_SWITCH';
export const UPDATE_FILTERS = namespace + 'UPDATE_FILTERS';
export const SET_SELECTED = namespace + 'SET_SELECTED';
export const SET_UNSELECTED = namespace + 'SET_UNSELECTED';
export const GET_EXPERIMENTS_REQUEST = namespace + 'GET_EXPERIMENTS_REQUEST';
export const GET_EXPERIMENTS_SUCCESS = namespace + 'GET_EXPERIMENTS_SUCCESS';
export const GET_EXPERIMENTS_ERROR = namespace + 'GET_EXPERIMENTS_ERROR';
export const SET_PAGINATION_INFO = namespace + 'SET_PAGINATION_INFO';
export const SET_SEARCH = namespace + 'SET_SEARCH';

export const toggleSwitch = (state) => ({
  type: TOGGLE_SWITCH,
  payload: state
});

export const updateFilters = (filters) => ({
  type: UPDATE_FILTERS,
  payload: filters
});

export const setSelected = (experimentId) => ({
  type: SET_SELECTED,
  payload: experimentId
});

export const setUnselected = (experimentId) => ({
  type: SET_UNSELECTED,
  payload: experimentId
});

export const getExperiments = ({
  sendRequest,
  conditions = [],
  machines = [],
  page = 1,
  variables = defaultVariables
}) => async (dispatch) => {
  try {
    dispatch({ type: GET_EXPERIMENTS_REQUEST });
    
    const { data } = await sendRequest('post', '/filterdata', {
      page,
      page_size: 10,
      conditions,
      machines,
      variables,
    });

    dispatch({ 
      type: GET_EXPERIMENTS_SUCCESS,
      payload: data.filterMainOutputDTOList
    });
    dispatch({
      type: SET_PAGINATION_INFO,
      payload: {
        currentPage: data.current_page,
        elementsOnPage: data.elements_on_page,
        pageSize: data.page_size,
        totalElements: data.total_elements,
        pages: data.pages
      }
    });
  } 
  catch (err) {
    const error = err.response ? err.response.data.message : err.message;
    dispatch({ type: GET_EXPERIMENTS_ERROR, payload: error });
  }
};

export const setSearch = (search) => {
  const searchParams = new URLSearchParams(search);

  if (searchParams.has('page')) {
    searchParams.delete('page');
  }

  if (Array.from(searchParams.entries()).length === 0) {
    return {
      type: SET_SEARCH,
      payload: ''
    };
  }

  return {
    type: SET_SEARCH,
    payload: '?' + searchParams.toString()
  };
};
