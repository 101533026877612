import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import VariablesTable from './variablesTable';
import Chart from './chart';
import EmptyChart from './emptyChart';
import Loading from '../../main/components/loading';
import { getChartTitle, getFileName } from '../utils';
import ValuesTable from './valuesTable';
import { idsTypeIds } from '../constants';

const CompareExperimentsCharts = ({ 
  error, 
  loading, 
  chartsData, 
  handleRemoveClick, 
  selectedVariableName,
  selectedVariableUnit,
  selectedExperimentsCount
}) => {
  const { t } = useTranslation();

  if (error) {
    return <h6 className='text-center mt-3'>{error}</h6>;
  }
  else if (loading) {
    return <Loading className='text-center mt-3' />;
  }
  else if (chartsData.length > 0) {
    if (chartsData.some(({ data }) => data && data.variableTimeDependent === 0)) {
      return <VariablesTable chartsData={chartsData} />;
    }
    else {
      return (
        <section className='p-3'>
          <Row>
            {chartsData.map(({ data, experimentInfo: { shot, run, machine }, id }) => (
              data !== null ? (
                <Col key={shot} md='6' className={"mb-3"}>
                  { data.idsTypeId !== idsTypeIds.pedestal ? <Chart
                    data={data.groupedEntryData}
                    title={getChartTitle(shot, run, machine, selectedVariableName)}
                    unit={selectedVariableUnit}
                    titleAlignment='top'
                    fileName={getFileName(data.idsField, shot, run, machine)}
                    width={553}
                    height={260}
                    showCloseBtn={selectedExperimentsCount > 2}
                    onClose={() => handleRemoveClick(id)}
                  /> :
                    <>
                      <span>{getChartTitle(shot, run, machine, selectedVariableName)}</span>
                      <ValuesTable groupedEntryData={data.groupedEntryData} />
                    </> }
                </Col>
              ) : (
                <Col key={shot} md='6'>
                  <EmptyChart
                    title={getChartTitle(shot, run, machine, selectedVariableName)}
                    noDataMessage={t('compare_experiments_no_data_message')}
                    width={553}
                    height={260}
                  />
                </Col>
              )
            ))}
          </Row>
        </section>
      );
    }
  }
  else {
    return null;
  }
};

CompareExperimentsCharts.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  chartsData: PropTypes.array,
  handleRemoveClick: PropTypes.func,
  selectedVariableName: PropTypes.string,
  selectedVariableUnit: PropTypes.string,
  selectedExperimentsCount: PropTypes.number
};

export default CompareExperimentsCharts;
