import { useState, useCallback } from 'react';

import { getLS, setLS } from '../../main/utils/localStorageProvider';

function useLocalStorage(key, initialValue) {
  const [ storedValue, setStoredValue ] = useState(() => {
    const item = getLS(key);
    return item ? JSON.parse(item) : initialValue;
  });

  const setValue = useCallback((value) => {
    setStoredValue(value);
    setLS(key, JSON.stringify(value));
  }, [ key ]);

  return [ storedValue, setValue ];
};

export default useLocalStorage;