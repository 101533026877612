import React, { useState } from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CompareExperimentsModal from './compareExperimentsModal';
import FiltersResult from './filtersResult';
import FiltersForm from './filtersForm';
import HistogramModal from './histogramModal';
import useLocalStorage from '../hooks/useLocalStorage';
import { ErrorBoundary } from '../../main/hoc/errorboundary';


const Filters = ({ variables, defaultFormControls }) => {
  const [ openCompareShotsModal, setOpenCompareShotsModal ] = useState(false);

  const [ histogramModal, setHistogramModal ] = useState({
    isOpen: false,
    idsField: '',
    variableName: '',
    unit: '',
    min: 0,
    max: 0
  });
  const [ initialFiltersValues, setInitialFiltersValues ] = useState({});

  const [ formControls, setFormControls ] = useLocalStorage('formControls', defaultFormControls);

  const { t } = useTranslation();

  const results = useSelector((state) => state.experiments.get('pagination').get('totalElements'));
  const selectedExperiments = useSelector((state) => state.experiments.get('selectedExperiments'));
  
  const toggleCompareShotsModal = () => setOpenCompareShotsModal((prev) => !prev);
  
  const onOpenHistogramModal = (idsField, variableName, unit, min, max) => {
    setHistogramModal({
      isOpen: true,
      idsField,
      variableName,
      unit,
      min,
      max
    });
    setInitialFiltersValues({});
  };

  const onCloseHistogramModal = (idsField, min, max) => {
    setHistogramModal(prev => ({ ...prev, isOpen: false }));

    if (min || max) {
      setInitialFiltersValues({
        [`${idsField}_min`]: min,
        [`${idsField}_max`]: max
      });
    }
  };

  return (
    <Container>
      <CompareExperimentsModal isOpen={openCompareShotsModal} toggle={toggleCompareShotsModal} variablesNames={variables}/>
      {histogramModal.isOpen && (
        <HistogramModal 
          isOpen={histogramModal.isOpen} 
          idsField={histogramModal.idsField}
          variableName={histogramModal.variableName}
          unit={histogramModal.unit}
          initialMinValue={histogramModal.min}
          initialMaxValue={histogramModal.max} 
          onCloseHistogramModal={onCloseHistogramModal}
        />
      )}
      <Row>
        <FiltersForm 
          onOpenHistogramModal={onOpenHistogramModal}
          initialFiltersValues={initialFiltersValues} 
          setFormControls={setFormControls}
          formControls={formControls}
          defaultFormControls={defaultFormControls}
          variables={variables}
        />
      </Row>
      <Row className={"my-3"}>
        <Col>
          <FiltersResult 
            allExperimentsCount={results} 
            selectedExperiments={selectedExperiments}
            formControls={formControls} 
          />
          <Button onClick={toggleCompareShotsModal} disabled={selectedExperiments.size < 2}>
            {t("open_compare_shots_modal_button")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

Filters.propTypes = {
  variables: PropTypes.object.isRequired,
  defaultFormControls: PropTypes.array.isRequired
};

export default compose(
  ErrorBoundary((props) => props.t('components.filters'))
)(Filters);