import React from 'react';
import PropTypes from 'prop-types';
import { Line, CartesianGrid, Label, LineChart, XAxis, YAxis } from 'recharts';

const EmptyChart = ({ title, noDataMessage, width, height }) => {
  return (
    <div className='chart-wrapper'>
      <span className='no-data-message'>{noDataMessage}</span>
      <LineChart width={width} height={height} margin={{ top: 50 }}>
        <XAxis>
          <Label className='chart-label' value={title} position='insideBottom' dy={-height + 25} />
        </XAxis>
        <YAxis />
        <CartesianGrid strokeDasharray='3 3 ' />
        <Line />
      </LineChart>
    </div>
  );
};

EmptyChart.propTypes = {
  title: PropTypes.string.isRequired,
  noDataMessage: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

export default EmptyChart;
