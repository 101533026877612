import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Pagination from './pagination';

const VariablesPagination = ({ experimentId }) => {
  
  const pagination = useSelector((state) => state.experimentOverview.get('pagination'));
  const currentPage = pagination.get('currentPage');
  const pages = pagination.get('pages');

  const lastSearch = useSelector((state) => state.experimentOverview.get('lastSearch'));

  const history = useHistory();

  const paginate = (page) => {
    if (lastSearch) {
      history.push(`/shots/${experimentId}${lastSearch}&page=${page}`);
    }
    else {
      history.push(`/shots/${experimentId}?page=${page}`);
    }
  };

  return <Pagination paginate={paginate} pages={pages} currentPage={currentPage} />;
};

VariablesPagination.propTypes = {
  experimentId: PropTypes.string.isRequired
};

export default VariablesPagination;
