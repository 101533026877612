import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import ExperimentsContainer from './experimentsContainer';
import Filters from './filters';
import { getUrlParamsFromFormControls } from '../utils';
import { checkIfLoginIsPossible } from '../../main/utils/authUtils';
import { getLS } from '../../main/utils/localStorageProvider';
import { defaultVariables } from '../constants';
import { withGetData } from '../../main/hoc/withGetData';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import Loading from '../../main/components/loading';

const Main = ({ history, variables }) => {
  const [ urlIsChecked, setUrlIsChecked ] = useState(false);

  const [ defaultFormControls, setDefaultFormControls ] = useState( null);

  useEffect(() => {
    if (variables.data && !defaultFormControls) {
      const defaultVariablesIds = defaultVariables.filter(({ idsField }) => idsField!=='ids_properties/creation_date').map(({ idsField }) => idsField);
      const dFC = defaultVariablesIds?.map( defaultIdsField => variables.data.find(({ idsField }) => idsField === defaultIdsField ));
      setDefaultFormControls(dFC);
    }
  }, [ variables, defaultFormControls ]);

  useEffect(() => {
    if (checkIfLoginIsPossible()) {
      const params = new URLSearchParams(window.location.search);
      const localStorageFormControls = getLS('formControls');
      if (!params.has('idsFields') && localStorageFormControls !== null) {
        const formControls = JSON.parse(localStorageFormControls);
        if (formControls.length) {
          const formControlsParams = getUrlParamsFromFormControls(formControls);
          history.push(`/shots?idsFields=${formControlsParams}`);
        }
      }
    }
    else {
      // TODO
    }
    setUrlIsChecked(true);
  }, [ history ]);

  if (!urlIsChecked) {
    return null;
  }

  return (
    <Container fluid className="mt-3">
      <Row>
        <Col xs="3">
          { defaultFormControls ? (<Filters variables={variables} defaultFormControls={defaultFormControls}/>) : (<Loading className='text-center my-3' />) }
        </Col>
        <Col xs="9">
          <ExperimentsContainer variables={variables}/>
        </Col>
      </Row>
    </Container>
  );
};

Main.propTypes = {
  variables: PropTypes.object.isRequired, // HOC
  history: PropTypes.object.isRequired // HOC
};

export default compose(
  withGetData({
    variables: '/variable/get-data'
  }),
  ErrorBoundary((props) => props.t('components.main'))
)(Main);