import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const VariablesTable = ({ chartsData }) => {
  const { t } = useTranslation();
  
  return (
    <Table className='mt-3' striped bordered>
      <thead>
        <tr>
          <th>{t('shot_label')}</th>
          <th>{t('run_label')}</th>
          <th>{t('machine_label')}</th>
          <th>{t('compare_experiments_table_value_label')}</th>
        </tr>
      </thead>
      <tbody>
        {chartsData.map(({ data, experimentInfo: { shot, run, machine } }) => (
          <tr key={shot}>
            <td>{shot}</td>
            <td>{run}</td>
            <td>{machine}</td>
            <td>
              {data !== null ? (
                data.groupedEntryData[0]?.variableValue
              ) : (
                <span className='font-weight-bold'>{t('compare_experiments_no_data_message')}</span>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

VariablesTable.propTypes = {
  chartsData: PropTypes.array
};

export default VariablesTable;
