import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { 
  Modal, 
  ModalBody, 
  ModalFooter, 
  ModalHeader, 
  Label, 
  Input, 
  InputGroup, 
  InputGroupAddon, 
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { withGetData } from '../../main/hoc/withGetData';
import { withSendRequest } from '../../main/hoc/withSendRequest';
import { setUnselected } from '../actions/experimentsList';
import { filterChartDataByIdsField, getVariableNameFromIdsField, getVariableUnitFromIdsField } from '../utils';
import CompareExperimentsCharts from '../components/compareExperimentsCharts';
import { ErrorBoundary } from '../../main/hoc/errorboundary';

const CompareExperimentsModal = ({ isOpen, toggle, variables, sendRequest, variablesNames }) => {
  const [ selectedVariable, setSelectedVariable ] = useState('');
  const [ selectedVariableName, setSelectedVariableName ] = useState('');
  const [ selectedVariableUnit, setSelectedVariableUnit ] = useState('');
  const [ allVariables, setAllVariables ] = useState([]);
  const [ chartsData, setChartsData ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');

  const { t } = useTranslation();

  const dispatch = useDispatch();
  
  const selectedExperiments = useSelector((state) => state.experiments.get('selectedExperiments'));

  useEffect(() => {
    if (variables.data) {
      setAllVariables(variables.data);
    }
  }, [ variables ]);

  useEffect(() => {
    if (!isOpen) {
      setChartsData([]);
      setError('');
      setSelectedVariable('');
      setSelectedVariableName('');
      setSelectedVariableUnit('');
    }
  }, [ selectedExperiments, isOpen ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setChartsData([]);

    if (!allVariables.includes(selectedVariable)) {
      setError('Variable does not exist!');
    }
    else {
      try {
        for (let id of selectedExperiments) {
          const { data: chartsData } = await sendRequest('post', '/entrydata/get-by-uri', {
            experiment_id: id,
            page: 1,
            page_size: 10,
            filters: [ { idsField: selectedVariable } ]
          });
          const response = await sendRequest('get', `experiment-description/${id}`);
          const experimentInfo = { ...response.data };
          experimentInfo['shot'] = experimentInfo['pulse']; // temporary

          setChartsData((prev) => [ ...prev, {
            data: filterChartDataByIdsField(chartsData, selectedVariable),
            experimentInfo: experimentInfo,
            id
          } ]);
        }
        setLoading(false);
      } 
      catch (err) {
        const error = err.response ? err.response.data : err.message;
        setError(error);
      }
    }
  };

  const handleRemoveClick = (id) => {
    dispatch(setUnselected(id));
    setChartsData((prev) => prev.filter(experiment => experiment.id !== id));
  };

  return (
    <Modal isOpen={isOpen} backdrop={true} toggle={toggle} size='xl' className='compare-modal'>
      <ModalHeader toggle={toggle}>{t('compare_experiments_modal_header')}</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <Label htmlFor='variables'>{t('compare_experiments_modal_variables_label')}{':'}</Label>
          <InputGroup>
            <Input 
              list='variables-datalist'
              type='text'
              name='variables'
              id='variables'
              onChange={(e) => {
                setSelectedVariableName(getVariableNameFromIdsField(variablesNames, e.target.value) || e.target.value);
                setSelectedVariableUnit(getVariableUnitFromIdsField(variablesNames, e.target.value) || e.target.value);
                setSelectedVariable(e.target.value);
              }}
              value={selectedVariable}
            />
            <datalist id='variables-datalist'>
              {allVariables.map(variable => (
                <option key={variable} value={variable}>{variable}</option>
              ))}
            </datalist>
            <InputGroupAddon addonType='append'>
              <Button type='submit' color='primary'>{t('compare_experiments_modal_show_button')}</Button>
            </InputGroupAddon>
          </InputGroup>
        </form>
        <CompareExperimentsCharts
          chartsData={chartsData}
          error={error}
          loading={loading}
          selectedVariableName={selectedVariableName}
          selectedVariableUnit={selectedVariableUnit}
          selectedExperimentsCount={selectedExperiments.size}
          handleRemoveClick={handleRemoveClick}
        />
      </ModalBody>
      <ModalFooter>
        <div className='d-flex justify-content-end'>
          <Button type='button' onClick={toggle}>Close</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

CompareExperimentsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  variables: PropTypes.object, // HOC
  sendRequest: PropTypes.func, // HOC
  variablesNames: PropTypes.object,
};

export default compose(
  withSendRequest,
  withGetData({ variables: '/variable/get-all-idsfields' }),
  ErrorBoundary((props) => props.t('components.compare_experiments_modal'))
)(CompareExperimentsModal);
