import React from 'react';
import { fromJS } from 'immutable';

import axios from '@axios';

// This hoc can be used to provide convenient way of getting data from api
// It stores data at specific prop of the component in object which consists of data itself and update method
export const withGetData = (getApiEndpoints) => (Component) => {
  return class withGetDataComponent extends React.Component {
    constructor(props) {
      super(props);

      const apiEndpoints = (typeof getApiEndpoints === 'function') ? getApiEndpoints(props) : getApiEndpoints;

      const initialStateObject = {};
      Object.keys(apiEndpoints).forEach(key => {
        initialStateObject[key] = {
          isLoading: false,
          error: null,
          data: null,
          update: async () => {
            try {
              this.setState({ 
                data: this.state.data
                  .setIn([ key, 'isLoading' ], true )
                  .setIn([ key, 'data' ], null )
                  .setIn([ key, 'eror' ], null )
              });
              const response = await axios.get(apiEndpoints[key]);
              this.setState({ 
                data: this.state.data.setIn([ key, 'data' ], response.data).setIn([ key, 'isLoading' ], false) 
              });
            }
            catch (err) {
              const error = err.response ? err.response.data : err.message;
              this.setState({
                data: this.state.data.setIn([ key, 'error' ], error)
              });
            }
          }
        };
      });

      this.state = {
        data: fromJS(initialStateObject)
      };
    }

    componentDidMount() {
      //Load data first time at the begining
      this.state.data.valueSeq().forEach(dataItem => dataItem.get("update")());
    }

    render() {
      return <Component {...this.props} {...this.state.data.toJS()} />;
    }
  };
};
