import React, { useState, useRef } from 'react';
import { 
  Form, 
  FormGroup, 
  Label, 
  InputGroup, 
  Input, 
  Button, 
  InputGroupAddon,
  CardHeader,
  Card,
  CardBody
} from 'reactstrap';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import useOnScreen from '../hooks/useOnScreen';

const VariablesForm = ({ variables, onSubmit }) => {
  const [ selectedOption, setSelectedOption ] = useState('');
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const { t } = useTranslation();

  const handleSelectChange = (e) => setSelectedOption(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault(); 
    onSubmit(selectedOption);
    if (!isVisible) {
      window.scrollBy({
        top: 100,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Card className='shadow-sm my-3'>
      <CardHeader>{t('variables_form.header')}</CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>{t('variables_form.input_label')}</Label>
            <InputGroup>
              <Input list='variables' type='text' value={selectedOption} onChange={handleSelectChange} />
              <datalist id='variables'>
                {variables.map(({ variableId, idsField }) => (
                  <option key={variableId} value={idsField}>{idsField}</option>
                ))}
              </datalist>
              <InputGroupAddon addonType='append'>
                <Button type='submit' color='primary' innerRef={ref}>{t('variables_form.submit_button')}</Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

VariablesForm.defaultProps = {
  variables: []
};

VariablesForm.propTypes = {
  variables: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default React.memo(VariablesForm);
