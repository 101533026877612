import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem, Button } from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import Loading from '../../main/components/loading';

const AdministrationRolesList = ({ error, roles, isLoading, deleteRole, addRole }) => {
  const { t } = useTranslation();

  const deleteUserRole = (roleId) => {
    deleteRole(roleId);
  };

  const addUserRole = (roleId) => {
    addRole(roleId);
  };

  if (error) {
    return <p className='text-center'>{error}</p>;
  }
  else if (!roles || isLoading) {
    return <Loading className='text-center' />;
  }
  else if (roles.length === 0) {
    return <p className='text-center'>{t('admin_view.roles_not_found')}.</p>;
  }
  else {
    return (
      <ListGroup>
        <TransitionGroup>
          {roles.map(({ id, name }) => (
            <CSSTransition
              key={id}
              timeout={500}
              classNames='list-animation-item'
            >
              <ListGroupItem>
                <div className='d-flex justify-content-between align-items-center'>
                  <span>{name}</span>
                  { deleteRole ?
                    <Button
                      onClick={() => deleteUserRole(id)}
                      color='link'
                      className='btn-bg-image btn-remove'
                      title={"Remove role for selected user"}
                    >
                    </Button> :
                    <Button
                      onClick={() => addUserRole(id)}
                      color='link'
                      className='btn-bg-image btn-collapse-closed'
                      title={"Add role for selected user"}
                    >
                    </Button> }
                </div>
              </ListGroupItem>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </ListGroup>
    );
  }
};

AdministrationRolesList.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  deleteRole: PropTypes.func,
  addRole: PropTypes.func,
  roles: PropTypes.arrayOf(PropTypes.any)
};

export default AdministrationRolesList;
