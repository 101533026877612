import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Pagination from './pagination';

const ExperimentsPagination = () => {
  const lastSearch = useSelector((state) => state.experiments.get('lastSearch'));
  const pagination = useSelector((state) => state.experiments.get('pagination'));

  const currentPage = pagination.get('currentPage');
  const pages = pagination.get('pages');

  const history = useHistory();

  const paginate = (page) => {
    if (lastSearch) {
      history.push(lastSearch + `&page=${page}`);
    }
    else {
      history.push(`?page=${page}`);
    }
  };

  return <Pagination paginate={paginate} currentPage={currentPage} pages={pages} />;
};

export default ExperimentsPagination;
