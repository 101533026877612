import React from 'react';

import axios from '@axios';

const sendRequest = (method, address, data = {}, retryCount = { "500": 0, "400": -1 }) => axios({
  method,
  url: address,
  data,
  __retryCount: retryCount
});

export const withSendRequest = (Component) => ({ ...props }) => {
  return <Component {...props} sendRequest={sendRequest} />;
};
