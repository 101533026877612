import React from 'react';
import { FormGroup, Label, Input, Button, InputGroup, InputGroupAddon } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TextFormControl = ({ label, name, value, onChange, onDelete }) => {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <Label>{label}</Label>
      <InputGroup>
        <Input
          name={name}
          type='text'
          value={value}
          onChange={onChange}
        />
        <InputGroupAddon addonType="append">
          <Button 
            color="primary" 
            className='py-1 px-2' 
            onClick={onDelete}
            title={t('delete_form_control_button_title')}
          >
            <FaTrash style={{ fontSize: '1.1rem' }} />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
  );
};

TextFormControl.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TextFormControl;
