import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Row, Col, Collapse, Button, Container, Alert } from 'reactstrap';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import { formatDate, getFileName, getVariableNameFromIdsField, getVariableUnitFromIdsField } from '../utils/index';
import { setSelected as setSelectedAction, setUnselected } from '../actions/experimentsList';
import Chart from '../components/chart';
import ExperimentAnnotationsModal from './experimentAnnotationsModal';
import ValuesTable from '../components/valuesTable';
import { idsTypeIds } from '../constants';

const ExperimentItem = ({ experiment, entryData, index, variables }) => {
  const { experiment_id, shot, run, machine } = experiment;

  const [ upperOpen, setUpperOpen ] = useState(false);
  const [ lowerOpen, setLowerOpen ] = useState(false);
  const [ selected, setSelected ] = useState(false);
  const [ annotationsModalOpen, setAnnotationsModal ] = useState(false);
  const [ chartsCount, setChartsCount ] = useState(0);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const switchOpen = useSelector((state) => state.experiments.get('switchOpen'));
  const currentFilters = useSelector((state) => state.experiments.get('currentFilters'));
  const selectedExperiments = useSelector((state) => state.experiments.get('selectedExperiments'));

  const cardHeaderClass = classNames({
    'zebra-odd': index % 2 === 1,
    'zebra-even': index % 2 === 0
  });

  const upperCollapseBtnClass = classNames('btn-bg-image', {
    'btn-collapse-open': upperOpen,
    'btn-collapse-closed': !upperOpen
  });

  const lowerCollapseBtnClass = classNames('btn-bg-image btn-toggle-lower', {
    'btn-collapse-open': lowerOpen,
    'btn-collapse-closed': !lowerOpen
  });

  useEffect(() => {
    if (!switchOpen && currentFilters.size === 0) {
      setUpperOpen(false);
    }
    else if (!switchOpen && currentFilters.size !== 0) {
      setUpperOpen(false);
    }
    else if (switchOpen && currentFilters.size !== 0) {
      setUpperOpen(true);
    }
  }, [ switchOpen, currentFilters ]);
  
  useEffect(() => {
    if (selectedExperiments.includes(experiment_id) && !selected) {
      setSelected(true);
    }
    else if (!selectedExperiments.includes(experiment_id) && selected) {
      setSelected(false);
    }
  },[ selectedExperiments, selected, experiment_id ]);

  useEffect(() => {
    if (Object.keys(entryData).length !== 0) {
      setChartsCount(entryData.filter((data) => data.variableTimeDependent === 1).length);
    }
  }, [ entryData ]);

  const toggleUpperOpen = () => setUpperOpen((prev) => !prev);

  const toggleLowerOpen = () => setLowerOpen((prev) => !prev);

  const handleCheckboxChange = () => {
    setSelected((prev) => !prev);

    if (!selected) {
      dispatch(setSelectedAction(experiment_id));
    }
    else {
      dispatch(setUnselected(experiment_id));
    }
  };

  const toggleAnnotationsModal = () => setAnnotationsModal((prev) => !prev);

  const showLowerCollapseButton = () => chartsCount > currentFilters.size; 
  
  const renderChartsWithFilters = () => (
    <>
      <div className='filtered-charts w-100'>
        <div className='d-flex flex-column align-items-center'>
          {entryData.slice(0, entryData.length)
            .filter(({ idsField }) => currentFilters.includes(idsField))
            .map((data) =>
              data.variableTimeDependent ? ( data.idsTypeId !== idsTypeIds.pedestal ?
                <Chart
                  key={data.idsField}
                  data={data.groupedEntryData}
                  title={getVariableNameFromIdsField(variables, data.idsField) || data.idsField}
                  unit={getVariableUnitFromIdsField(variables, data.idsField) || ' '}
                  boldLabel
                  fileName={getFileName(data.idsField, shot, run, machine)}
                /> :
                <Col className={"pedestal-entry"}>
                  <span className={"font-weight-bold"}>{data.idsField}</span>
                  <ValuesTable groupedEntryData={data.groupedEntryData} />
                </Col>
              ) : (null)
            )
          }
        </div>
        {showLowerCollapseButton() ? 
          <Button 
            color='link' 
            className={lowerCollapseBtnClass}
            onClick={toggleLowerOpen}
            title={t('expand_collapse_section_label')}
          >
          </Button>
          : null}
      </div>
      <Collapse isOpen={lowerOpen}>
        <div className='d-flex flex-column align-items-center'>
          {entryData.slice(0, entryData.length)
            .filter(({ idsField }) => !currentFilters.includes(idsField))
            .map((data) =>
              data.variableTimeDependent ? ( data.idsTypeId !== idsTypeIds.pedestal ?
                <Chart
                  key={data.idsField}
                  data={data.groupedEntryData}
                  title={getVariableNameFromIdsField(variables, data.idsField) || data.idsField}
                  unit={getVariableUnitFromIdsField(variables, data.idsField) || ' '}
                  fileName={getFileName(data.idsField, shot, run, machine)}
                /> :
                <Col className={"pedestal-entry"}>
                  <span>{data.idsField}</span>
                  <ValuesTable groupedEntryData={data.groupedEntryData} />
                </Col>
              ) : (null)
            )
          }
        </div>
      </Collapse>
    </>
  );

  const renderChartsWithoutFilters = () => (
    <div className='d-flex flex-column align-items-center'>
      {entryData.slice(0, entryData.length).map((data) =>
        data.variableTimeDependent ? ( data.idsTypeId !== idsTypeIds.pedestal ?
          <Chart
            key={data.idsField}
            data={data.groupedEntryData}
            title={getVariableNameFromIdsField(variables, data.idsField) || data.idsField}
            unit={getVariableUnitFromIdsField(variables, data.idsField) || ' '}
            fileName={getFileName(data.idsField, shot, run, machine)}
          /> :
          <Col className={"pedestal-entry"}>
            <span>{data.idsField}</span>
            <ValuesTable groupedEntryData={data.groupedEntryData} />
          </Col>
        ) : (null)
      )}
    </div>
  );

  if (Object.keys(entryData).length === 0) {
    return (
      <Alert color='danger' className='entry-data-not-found-alert'>
        <Trans i18nKey='entry_data_error_message'>
          Entry data for experiment with id <strong>{{ id: experiment_id }}</strong> not found
        </Trans>
      </Alert>
    );
  };

  const wrongImport = (shot===null || run===null || machine===null);

  return (
    <>
      { annotationsModalOpen && <ExperimentAnnotationsModal
        experimentId={experiment.experiment_id} 
        isOpen={annotationsModalOpen}
        toggle={toggleAnnotationsModal}
      /> }
      <Card>
        <CardHeader className={cardHeaderClass + (wrongImport ? " wrong-import":"")}>
          <Container>
            <Row className='align-items-center' title={wrongImport ? t('unknown_label'):null}>
              <Col md='1'>
                <input type='checkbox' checked={selected} onChange={handleCheckboxChange} />
              </Col>
              <Col>
                <span>{t('shot_label')}:{' '}{shot || t('unknown')}</span>
              </Col>
              <Col>
                <span>{t('run_label')}:{' '}{run || t('unknown')}</span>
              </Col>
              <Col>
                <span>{t('machine_label')}:{' '}{machine || t('unknown')}</span>
              </Col>
              <Col>
                <span>{t('date_label')}:{' '}{formatDate(entryData?.find(
                  eData => eData.idsField === "ids_properties/creation_date").groupedEntryData[0]?.variableValue)}</span>
              </Col>
              <Col>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='d-flex align-items-center'>
                    <Link 
                      to={`/shots/${experiment_id}`}
                      className='open-shot-link btn-bg-image btn-open mr-2' 
                      title={t('open_shot_details_label')}
                    >
                    </Link>
                    <Button 
                      onClick={toggleAnnotationsModal}
                      color='link' 
                      className='btn-bg-image btn-annotate' 
                      title={t('open_shot_annotations_label')}
                    >
                    </Button>
                  </div>
                  <span>
                    <Button 
                      color='link' 
                      className={upperCollapseBtnClass}
                      title={t('expand_collapse_section_label')}
                      onClick={toggleUpperOpen}
                    >
                    </Button>
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <Collapse isOpen={upperOpen}>
          <CardBody>
            {currentFilters.size === 0 ? renderChartsWithoutFilters() : renderChartsWithFilters()}
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
};

ExperimentItem.propTypes = {
  experiment: PropTypes.shape({
    experiment_id: PropTypes.number.isRequired,
    shot: PropTypes.string.isRequired,
    run: PropTypes.string.isRequired,
    machine: PropTypes.string.isRequired,
    uri: PropTypes.string,
    user: PropTypes.string,
    version: PropTypes.string,
    date: PropTypes.string,
  }),
  entryData: PropTypes.arrayOf(PropTypes.shape({
    idsField: PropTypes.string,
    variableTimeDepedent: PropTypes.number,
    variableType: PropTypes.number,
    groupedEntryData: PropTypes.arrayOf(PropTypes.shape({
      variableValue: PropTypes.string,
      time: PropTypes.number
    }))
  })),
  index: PropTypes.number.isRequired,
  variables: PropTypes.object,
};

export default ExperimentItem;
