import { __env } from '../../envloader';

export const defaultVariables = __env.DASHBOARD_FLAVOUR_NUMBER===1 ? [
  { idsField: 'ids_properties/creation_date' },
  { idsField: 'global_quantities/ip/value' },
  { idsField: 'global_quantities/b0/value' },
  { idsField: 'global_quantities/q_95/value' }
] : [
  { idsField: 'ids_properties/creation_date' },
  { idsField: 'global_quantities/ip/value' },
  { idsField: 'global_quantities/b0/value' },
  { idsField: 'global_quantities/q_95/value' },
  { idsField: 'global_quantities/power_ohm/value' },
];

export const variablesTypes = [
  { id: 1, value: "integer" }, 
  { id: 2, value: "double" }, 
  { id: 3, value: "complex" }, 
  { id: 4, value: "string" }
];

export const idsTypeIds = {
  simulation: 0,
  experimental_data: 1,
  pedestal: 2,
};
