import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { CardHeader, Input, Button, Form, Row, Col } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ExperimentAnnotationsModal from './experimentAnnotationsModal';
import { withSendRequest } from '../../main/hoc/withSendRequest';
import { getExperimentIdsFields } from '../actions/experimentOverview';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { formatDate } from '../utils';

const ExperimentViewHeader = ({ experimentId, sendRequest }) => {
  const [ selectedVariable, setSelectedVariable ] = useState('');
  const [ annotationsModalOpen, setAnnotationsModal ] = useState(false);

  const lastSearch = useSelector((state) => state.experiments.get('lastSearch'));
  const currentPage = useSelector((state) => state.experiments.get('pagination').get('currentPage'));
  const experimentInfo = useSelector((state) => state.experimentOverview.get('experimentInfo').get('data'));
  const { shot, run, machine, creationDate } = experimentInfo;
  const experimentIdsFields = useSelector((state) => state.experimentOverview.get('experimentIdsFields').get('data'));
  const experimentIdsFieldsLoading = useSelector((state) => state.experimentOverview.get('experimentIdsFields').get('loading'));

  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const search = window.location.search;

  useEffect(() => { 
    if (!experimentIdsFields || experimentId !== experimentIdsFields.experimentId) {
      if (!experimentIdsFieldsLoading) {
        dispatch(getExperimentIdsFields({ sendRequest, experimentId }));
      }
    }
  }, [ dispatch, sendRequest, experimentId, experimentIdsFields, experimentIdsFieldsLoading ]);

  useEffect(() => {
    const params = new URLSearchParams(search);

    if (params.has('name')) {
      setSelectedVariable(params.get('name'));
    }
  }, [ search ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedVariable) {
      history.push(`/shots/${experimentId}?name=${selectedVariable}`);
    }
    else {
      history.push(`/shots/${experimentId}`);
    }
  };

  const handleSelectChange = (e) => setSelectedVariable(e.target.value);

  const backToListLink = () => {
    if (lastSearch) {
      return `/shots${lastSearch}${currentPage ? `&page=${currentPage}` : ''}`;
    }
    else {
      return `/shots${currentPage ? `?page=${currentPage}` : ''}`;
    }
  };

  const toggleAnnotationsModal = () => setAnnotationsModal((prev) => !prev);

  return (
    <>
      { annotationsModalOpen && <ExperimentAnnotationsModal
        experimentId={Number(experimentId)}
        isOpen={annotationsModalOpen}
        toggle={toggleAnnotationsModal}
      /> }
      <CardHeader>
        <Row className='align-items-center'>
          <Col>
            <span>{t('shot_label')}:{' '}{shot}</span>
            <span></span>
          </Col>
          <Col>
            <span>{t('run_label')}:{' '}{run}</span>
            <span></span>
          </Col>
          <Col>
            <span>{t('machine_label')}:{' '}{machine} </span>
            <span></span>
          </Col>
          <Col>
            <span>{t('date_label')}:{' '}{formatDate(creationDate)} </span>
            <span></span>
          </Col>
          <Col md='1'>
            <div className='d-flex align-items-center'>
              <Link
                to={backToListLink}
                className='btn-bg-image btn-close mr-2'
                title={t('experiment_view_header.back_to_experiments_list')}
              >
              </Link>
              <Button
                onClick={toggleAnnotationsModal}
                color='link'
                className='btn-bg-image btn-annotate'
                title={t('open_shot_annotations_label')}
              >
              </Button>
            </div>
          </Col>
          <Col md='4'>
            <Form onSubmit={handleSubmit}>
              <div className='d-flex'>
                <Input list='variables' type='text' value={selectedVariable} onChange={handleSelectChange} />
                {experimentIdsFields && (
                  <datalist id='variables'>
                    {experimentIdsFields.items.map(variable => <option key={variable} value={variable}>{variable}</option>)}
                  </datalist>
                )}
                <Button color='primary' className='experiment-view-search-btn' id='search-button'>
                  {t('experiment_view_header.search_button_text')}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </CardHeader>
    </>
  );
};

ExperimentViewHeader.propTypes = {
  experimentId: PropTypes.string,
  sendRequest: PropTypes.func.isRequired, // HOC
};

export default memo(compose(
  withSendRequest,
  ErrorBoundary((props) => props.t('components.experiment_view_header'))
)(ExperimentViewHeader));
