import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem, Button } from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import Loading from '../../main/components/loading';

const AdministrationUsersList = ({ error, users, isLoading, chooseUser, selectedId }) => {
  const { t } = useTranslation();

  const showUserRoles = (userId) => {
    chooseUser(userId);
  };

  if (error) {
    return <p className='text-center'>{error}</p>;
  }
  else if (!users || isLoading) {
    return <Loading className='text-center' />;
  }
  else if (users.length === 0) {
    return <p className='text-center'>{t('admin_view.users_not_found')}.</p>;
  }
  else {
    return (
      <ListGroup>
        <TransitionGroup>
          {users.map(({ id, userName }) => (
            <CSSTransition
              key={id}
              timeout={500}
              classNames='list-animation-item'
            >
              <ListGroupItem className={selectedId===id && "selected"}>
                <div className='d-flex justify-content-between align-items-center'>
                  <span>{userName}</span>
                  <Button
                    onClick={() => showUserRoles(id)}
                    color='link'
                    className='btn-bg-image btn-open'
                    title={"Show user roles"}
                  >
                  </Button>
                </div>
              </ListGroupItem>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </ListGroup>
    );
  }
};

AdministrationUsersList.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  chooseUser: PropTypes.func,
  selectedId: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.any)
};

export default AdministrationUsersList;
