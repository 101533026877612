import React, { useState } from 'react';
import {
  Pagination as ReactstrapPagination,
  PaginationItem,
  PaginationLink,
  Form,
  Input,
  Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { range } from '../utils';


const Pagination = ({ paginate, currentPage, pages, limit = 4 }) => {
  const [ inputPage, setInputPage ] = useState(1);
  
  const { t } = useTranslation();
  
  const canNext = () => currentPage <= pages - 1;

  const canPrevious = () => currentPage > 1;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputPage) {
      paginate(inputPage);
    }
  };
  
  const renderPageNumbers = () => {
    let start = 1;

    if (currentPage >= 3) {
      start = currentPage - 2;
    }

    let stop = start + (limit - 1);

    if (currentPage + (limit - 1) > pages) {
      stop = pages;
    }

    const pageNumbers = range(start, stop + 1);

    return (
      <>
        {start !== 1 && (
          <PaginationItem disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        )}
        {pageNumbers.map(pageNum => (
          <PaginationItem
            key={pageNum}
            active={pageNum === currentPage}
            title={`${t('go_to_page_with_number_label')} ${pageNum}`}
          >
            <PaginationLink onClick={() => paginate(pageNum)}>{pageNum}</PaginationLink>
          </PaginationItem>
        ))}
        {stop !== pages && (
          <PaginationItem disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        )}
      </>
    );
  };


  return (
    <section className='d-flex justify-content-center mt-2'>
      <ReactstrapPagination>
        <PaginationItem disabled={currentPage === 1} title='Go to first page'>
          <PaginationLink first onClick={() => paginate(1)} />
        </PaginationItem>
        <PaginationItem disabled={!canPrevious()} title='Go to previous page'>
          <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
        </PaginationItem>
        {renderPageNumbers()}
        <PaginationItem disabled={!canNext()} title='Go to next page'>
          <PaginationLink next onClick={() => paginate(currentPage + 1)} />
        </PaginationItem>
        <PaginationItem disabled={currentPage === pages} title='Go to last page'>
          <PaginationLink last onClick={() => paginate(pages)} />
        </PaginationItem>
        <Form inline onSubmit={handleSubmit}>
          <Input
            type='number'
            min='1'
            max={pages}
            value={inputPage}
            onChange={e => setInputPage(e.target.value)}
            className='pagination-input'
          />
          <Button type='submit' color='primary' className='pagination-button'>{t('go_to_page_label')}</Button>
        </Form>
      </ReactstrapPagination>
    </section>
  );
};

Pagination.propTypes = {
  paginate: PropTypes.func.isRequired,
  pages: PropTypes.number,
  currentPage: PropTypes.number,
  limit: PropTypes.number
};

export default Pagination;
